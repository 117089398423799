<template lang="pug">
baseDataTables(
  :rows="rows",
  :headings="headings",
  :options="options",
  ref="dataTable"
)
</template>
<script>
export default {
  name: "dataTable",
  props: {
    rows: Object,
    headings: Array,
    options: {
      type: Object,
      default: new Object(),
    },
  },
  methods: {
    exportDataTable() {
      this.$refs.dataTable.exportDataTable();
    },
  },
};
</script>
<style lang="scss" src="./style.scss" />
